/* eslint-disable no-loop-func */
import { action, observable } from 'mobx'
import { api } from '../API'
import { api_other } from '../API_Other'
import Geocode from 'react-geocode'
import Notification from '../customComponents/Notification/Notigication'
import intl from 'react-intl-universal'
interface sendData {
    fullname: string
    phone: string
    coord: { lat: number; lng: number }
    address?: string
}

interface IDistrictPoligon {
    color: string
    polygon: Array<{ lat: number; lng: number }>
    name: string
}

Geocode.setApiKey('AIzaSyBdmt7ZjZOmOsVpunOctznVNy39xKg7qoc')
Geocode.setLanguage('ru')

export class MapStore {
    
    @observable _poligonArray: Array<Array<{ lat: number; lng: number }>> = []
    @observable _isLoading: boolean = true
    @observable _isSendingData: boolean = false
    @observable _addressGoogle: string = ''
    @observable _districPoligon: Array<IDistrictPoligon> = []
    @observable _isSuccess: boolean = false
    @observable isLK: boolean = window.location.pathname.includes('/LK')


    @action
    getCordPoligons = async () => {
        try {
            const response = await api.get('building')
            let arrayObject: Array<any> = []
            let bArray: Array<any> = []
            for (let key of response.data) {
                // eslint-disable-next-line array-callback-return
                key.coordinates.map((cord: Array<string>) => {
                    for (let key of cord) {
                        let a = { lat: key[0], lng: key[1] }
                        arrayObject.push(a)
                    }
                    bArray.push(arrayObject)
                })
                arrayObject = []
            }

            this._poligonArray = bArray
        } catch (error) {
            console.log('error', error)
        } finally {
            this._isLoading = false
        }
    }
    @action
    sendDataForm = async (data: sendData, captcha: string) => {
        this._isSendingData = true
        try {
            await api_other.post('map_callback/?g_recaptcha_response=' + captcha, data)
            Notification({
                content: intl.get('Заява успешно отправлена'),
                type: 'success',
            })
        } catch (error) {
            Notification({
                content: intl.get(
                    'Заява не была отправлена, попробуйте еще раз'
                ),
                type: 'error',
            })
            return 'error'
        } finally {
            this._isSendingData = false
        }
    }
    @action
    getAddressGoogle = async (lat: string, lgn: string) => {
        try {
            const response = await Geocode.fromLatLng(lat, lgn)
            const address = response.results[0].formatted_address
            this._addressGoogle = address
        } catch (error) {
            console.error(error)
        }
    }
    @action
    getDistricPoligons = async () => {
        try {
            const response = await api.get('polygon/')
            let arrayObject: Array<{ lat: number; lng: number }> = []
            let bArray: Array<IDistrictPoligon> = []
            for (let key of response.data) {
                let newObject: IDistrictPoligon = {
                    color: '',
                    polygon: [{ lat: 0, lng: 0 }],
                    name: '',
                }
                newObject.color = key.color
                newObject.name = key.name
                // eslint-disable-next-line array-callback-return
                key.coord.map((cord: Array<number>) => {
                    let a = { lat: cord[0], lng: cord[1] }
                    arrayObject.push(a)
                    newObject.polygon = arrayObject
                })
                bArray.push({ ...newObject })
                arrayObject = []
            }
            this._districPoligon = bArray
        } catch (error) {}
    }
}

import { useLayoutEffect, useState } from 'react'
import { MapStore } from './stores/MapStore'
import { StoreProvider } from './stores/store'
import { BrowserRouter } from 'react-router-dom'
import { Router } from './Router'
import { ToastContainer } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.min.css'
import intl from 'react-intl-universal'
import ua from './localization/ua'
import ru from './localization/ru'

const locales = {
    ua,
    ru,
}

export const stores = {
    mapStore: new MapStore(),
}

export type TypeStore = typeof stores

const App = () => {
    const [initDone, setInitDone] = useState<boolean>(false)
    let lang = localStorage.getItem('lang') === 'ua' ? 'ua' : 'ru'

    useLayoutEffect(() => {
        loadLocales()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])
    const loadLocales = () => {
        if (lang) {
            intl.init({
                currentLocale: lang,
                locales,
            }).then(() => {
                setInitDone(true)
            })
        } else {
            intl.init({
                currentLocale: 'ru',
                locales,
            }).then(() => {
                setInitDone(true)
            })
        }
    }

    if (initDone) {
        return (
            <StoreProvider store={stores}>
                <BrowserRouter>
                    <Router />
                    <ToastContainer />
                </BrowserRouter>
            </StoreProvider>
        )
    }
    return null
}

export default App

import { Switch, Route } from 'react-router-dom'
import { MyCover } from './Components/GoogleMap/MyCover'

export const Router = () => {
    return (
        <Switch>
            <Route path='/:lang(ru|ua)?/:area(aleksandrovskiy|voznesenovskiy|pravyiy|kichkas|kosmos|baburka|peski|gortop|balabino|veselyanka|grigorievka|ivanoanovka|kushugum|lezhino|natalovka|novoolenivka|novoaleksandrovka)?' exact component={MyCover} />
            <Route path='/:lang(ru|ua)?/:isModal(modal)?/:city?' exact component={MyCover} />
            <Route path='/:lang(ru|ua)?/:isModal(modal)?/:lat?/:lan?' exact component={MyCover} />
            <Route path='/:lang(ru|ua)?/:isModal(modal)?/:isLK(LK)?/:lat?/:lan?' exact component={MyCover} />
        </Switch>
    )
}

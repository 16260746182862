import { useState } from 'react'
import './index.css'
import intl from 'react-intl-universal'

const contacts: Array<string> = ['050 707 41 14', '061 707 41 14', '068 707 41 14', '073 707 41 14', '0 800 30 41 14']

const contact = (number: string, index: number) => {
    return (
        <div key={`number-support-${index}`}>
            <a href={`tel:${number.includes('0 800')? number : '38'+number}`}> { number.includes('0 800') ? number : '+38 '+number}</a>
        </div>
    )
}

const renderContacts = () => {
    return <div className='blockNumber'> {contacts.map((contactSupport, index) => contact(contactSupport, index))}</div>
}

export const SupportContact = () => {
    const [open, setOpen] = useState<boolean>(false)
    const openContacts = () => {
        setOpen(!open)
    }
    return (
        <div>
            <div className='contacts' onClick={openContacts}>
                <div>{intl.get('Контакты')}</div>
            </div>
            {open && renderContacts()}
        </div>
    )
}

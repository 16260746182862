import React from 'react'
import MaskedInput from 'react-text-mask'
import classnames from 'classnames'
import './index.css'

interface PropsInputCustom {
    value: string
    onChange: (e: React.ChangeEvent<HTMLInputElement>) => void
    label: string
    error?: string
    className?: string
    autoFocus?: boolean
}

export const InputCustom = (props: PropsInputCustom) => {
    const { value, onChange, label, error, className, autoFocus } = props

    const renderError = () => {
        return <div className='errorInput'>{error}</div>
    }

    return (
        <div className={classnames('inputCustom', error!.length > 0 && 'errorBorder', className)}>
            <label htmlFor='phone' className={`label-input ${className ? className : ''}`}>
                {label}
                <span className='required'>*</span>
            </label>
            <MaskedInput
                inputMode='numeric'
                style={{
                    border: error!.length > 0 ? '1px solid #e57373' : ' 1px solid #b2bece',
                }}
                mask={[/[0]/,/\d/, /\d/, ' ', /\d/, /\d/, /\d/, ' ', /\d/, /\d/, ' ', /\d/, /\d/]}
                value={value}
                onChange={onChange}
                className={'PhoneInput'}
                autoFocus={autoFocus}
            />
            <span className='startNumber'>+38</span>
            {renderError()}
        </div>
    )
}

/* eslint-disable import/no-anonymous-default-export */
export default {
    Покрытие: 'Покрытие',
    Александровский: 'Александровский',
    Вознесеновский: 'Вознесеновский',
    'Бульвар Шевченко': 'Бульвар Шевченко',
    'Южный рынок': 'Южный рынок',
    Чапаева: 'Чапаева',
    Металлургов: 'Металлургов',
    'Первомайский пос.': 'Первомайский пос.',
    Молочная: 'Молочная',
    'Мирный поселок': 'Мирный поселок',
    Культурная: 'Культурная',
    Чкаловский: 'Чкаловский',
    Кичкас: 'Заводский',
    'Космос МЖД': 'Космос МЖД',
    'Второмайский пос.': 'Второмайский пос.',
    Космос: 'Космический',
    Днепровский: 'Днепровский',
    Хортицкий: 'Хортицкий',
    Южный: 'Южный',
    Шевченковский: 'Шевченковский',
    Балабино: 'Балабино',
    Кушугум: 'Кушугум',
    Наталовка: 'Наталовка',
    'Ивано-Анновка': 'Ивано-Анновка',
    Лежино: 'Лежино',
    Новоеленовка: 'Новооленовка',
    Новоалександровка: 'Новоалександровка',
    Григоровка: 'Григоровка',
    Веселянка: 'Веселянка',
    'Введите адрес': 'Введите адрес',
    Контакты: 'Контакты',
    'Запорожье': 'г. Запорожье',
    'Область': 'Запорожская об-ть',

    'Заява на проверку возможности подключения:': 'Заявка на проверку возможности подключения:',
    'Введите ФИО': 'Введите ФИО',
    'Телефон:': 'Телефон',
    'Ваш адрес': 'Ваш адрес:',
    Отправить: 'Отправить',
    'Ваше имя:': 'Ваше имя',
    'Ведите адрес': 'Введите адрес',

    'Введите Ваше имя!': 'Введите Ваше имя!',
    'Введите Ваш номер телефона!': 'Введите Ваш номер телефона!',
    'ЗАЯВКА НА ПОДКЛЮЧЕНИЕ:': 'Обратный звонок:',
    Подключить: 'Подключить',
    Закрыть: 'Закрыть',

    'интернет-провйдер в городе Запорожье': 'интернет-провйдер в городе Запорожье',
    'График работы: ежедневно 8:00 - 20:00': 'График работы: ежедневно 8:00 - 20:00',
    'Техническая поддержка работает круглосуточно': 'Техническая поддержка работает круглосуточно',

    'Заява успешно отправлена': 'Заявка успешно отправлена',
    'Заява не была отправлена, попробуйте еще раз': 'Заявка не была отправлена, попробуйте еще раз',
}

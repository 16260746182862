import { useEffect, useState } from 'react'
import './index.css'
import '../../customComponents/InputCustom/index.css'
import { useStore } from '../../stores/store'
import { observer } from 'mobx-react'
import { captchaKey } from '../../API'
import intl from 'react-intl-universal'
import CloseForm from '../../assets/img/cancel.svg'
import Girl from '../../assets/img/laptop_girl.png'
import { InputCustom } from '../../customComponents/InputCustom/InputCustom'
import Recaptcha from '../Captcha/Recaptcha'

interface PropsFormSendData {
    latLng: { lat: number; lng: number }
    closeForm: () => void
    addressLocation: string
}

export const FormSendData: React.FC<PropsFormSendData> = observer((props) => {
    const { addressLocation } = props
    const [name, setName] = useState<string>('')
    const [phone, setPhone] = useState<string>('')
    const [error, changeError] = useState(false)
    const [address, setAddress] = useState<string>(addressLocation)
    const [isShowCaptcha, changeShowCaptcha] = useState<boolean>(false)

    const { mapStore } = useStore()

    useEffect(() => {
        setAddress(addressLocation)
    }, [addressLocation])

    const onChangeName = (e: React.ChangeEvent<HTMLInputElement>) => {
        setName(e?.target?.value)
    }
    const onChangePhone = (e: React.ChangeEvent<HTMLInputElement>) => {
        setPhone(e?.target?.value)
    }

    const checkPhone = () => /^\d{10}$/.test(phone.replaceAll(' ', ''))
    const validateForm = () => {
        if (!checkPhone()) {
            changeError(true)
        } else {
            if (!error) changeError(false)
            changeShowCaptcha(true)
            //sendData()
        }
    }

    const sendData = async (captcha: string | null) => {
        //console.log(error)
        if (captcha) {
            let data = {
                fullname: name || 'клієнт',
                phone: `+38${ phone.replaceAll(' ', '').replaceAll('-', '') }`,
                coord: props.latLng,
                address: address,
            }
            const res = await mapStore!.sendDataForm(data, captcha)
            console.log(data)
            if (!res) props.closeForm()
        }
    }

    const onChangeAddress = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
        setAddress(e?.target?.value)
    }

    return (
        <div className='PopUpModal'>
            <div className='content'>
                <div className='modalContainer'>
                    <div className='modalData'>
                        <div className='dataClientContainer'>
                            <h2>{intl.get('ЗАЯВКА НА ПОДКЛЮЧЕНИЕ:')}</h2>
                            <div className='form'>
                                <input
                                    id='name'
                                    type='text'
                                    value={name}
                                    onChange={onChangeName}
                                    className={`${error && !name ? 'errorName' : ''}`}
                                    placeholder={intl.get('Ваше имя:')}
                                />
                                {error && !name && (
                                    <div className='errorInput'>
                                        {intl.get('Введите Ваше имя!')}
                                    </div>
                                )}
                                <InputCustom
                                    label={intl.get('Телефон:')}
                                    value={phone}
                                    onChange={onChangePhone}
                                    className={`${error && !checkPhone() ? 'error' : ''}`}
                                    error={
                                        error && !checkPhone()
                                            ? intl.get('Введите Ваш номер телефона!')
                                            : ''
                                    }
                                />
                                <div className='addressBox'>
                                    <label htmlFor='address' className={'label-input'}>
                                        {intl.get('Ваш адрес')}
                                    </label>
                                    <textarea
                                        id='address'
                                        rows={2}
                                        placeholder={intl.get('Ведите адрес')}
                                        value={address}
                                        onChange={onChangeAddress}
                                        style={{ resize: 'none' }}
                                    />
                                </div>
                                {isShowCaptcha && captchaKey ? (
                                    <Recaptcha sendData={sendData} />
                                ) : (
                                    <div className='sendBtnContainer'>
                                        <button onClick={props.closeForm}>
                                            {intl.get('Закрыть')}
                                        </button>
                                        <button className='btnSend' onClick={validateForm}>
                                            {intl.get('Отправить')}
                                        </button>
                                    </div>
                                )}
                            </div>
                        </div>
                    </div>
                    <div className='modal-img'>
                        <img src={Girl} alt='' />
                    </div>
                </div>
                {/* <div className="modal-close">
                    <img src="@/assets/images/icons/close-modal.svg" alt="" />
                </div> */}
                <div className='btnClose' onClick={props.closeForm}>
                    <img src={CloseForm} alt='close form' />
                </div>
            </div>
        </div>
    )
})

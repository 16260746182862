import axios from 'axios'
export const backURL = process.env.REACT_APP_API_LINK_OTHER
export let baseURL = `${backURL}`

export const api_other = axios.create({
    baseURL,
})

export const setHeadersBearer = (bearer: string) => {
    if (bearer !== null) {
        api_other.defaults.headers.common = {
            Authorization: `Bearer ${bearer}`,
        }
    }
}

export const setHeadersToken = (token: string) => {
    if (token) {
        api_other.defaults.headers.common = {
            Authorization: `Token ${token}`,
        }
    }
}

export const removeHeaders = () => {
    api_other.defaults.headers.common = {
        Authorization: ``,
    }
}

import React from 'react'
import { toast } from 'react-toastify'
import { NotificationBox } from './NotificationBox'

interface INotification {
    content: string
    title?: string | null
    type?: string
}

const Notification = (options: INotification) => {
    switch (options.type) {
        case 'success':
            toast.success(<NotificationBox data={options} />)
            break
        case 'error':
            toast.error(<NotificationBox data={options} />)
            break
        case 'warning':
            toast.warning(<NotificationBox data={options} />)
            break
        case 'info':
            toast.info(<NotificationBox data={options} />)
            break
        default:
            toast.info(<NotificationBox data={options} />)
            break
    }
}
export default Notification

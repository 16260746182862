export interface District {
    name: string
    polygon: { lat: number; lng: number }
    zoom: number
    type: 'city' | 'country'
    id: string
}
export interface City {
    name: string
    polygon: { lat: number; lng: number }
    zoom: number
}
export const districts: Array<District> = [
    {
        name: 'Александровский',
        polygon: { lat: 47.8111077, lng: 35.1804433 },
        zoom: 14.5,
        type: 'city',
        id: 'aleksandrovskiy',
    },
    {
        name: 'Вознесеновский',
        polygon: { lat: 47.8378013, lng: 35.1238411 },
        zoom: 14,
        type: 'city',
        id: 'voznesenovskiy',
    },
    {
        name: 'Днепровский',
        polygon: { lat: 47.870917, lng: 35.073619 },
        zoom: 14,
        type: 'city',
        id: 'pravyiy',
    },
    {
        name: 'Кичкас',
        polygon: { lat: 47.8809248, lng: 35.1453257 },
        zoom: 14,
        type: 'city',
        id: 'kichkas',
    },
    {
        name: 'Космос',
        polygon: { lat: 47.790525, lng: 35.213462 },
        zoom: 14,
        type: 'city',
        id: 'kosmos',
    },
    {
        name: 'Хортицкий',
        polygon: { lat: 47.812751, lng: 35.040481 },
        zoom: 15.5,
        type: 'city',
        id: 'baburka',
    },
    {
        name: 'Южный',
        polygon: { lat: 47.780648, lng: 35.183636 },
        zoom: 15.5,
        type: 'city',
        id: 'peski',
    },
    {
        name: 'Шевченковский',
        polygon: { lat: 47.817019, lng: 35.195405 },
        zoom: 16,
        type: 'city',
        id: 'gortop',
    },
    {
        name: 'Балабино',
        polygon: { lat: 47.741867, lng: 35.21679 },
        zoom: 14,
        type: 'country',
        id: 'balabino',
    },
    {
        name: 'Веселянка',
        polygon: { lat: 47.684058, lng: 35.373993 },
        zoom: 14,
        type: 'country',
        id: 'veselyanka',
    },
    {
        name: 'Григоровка',
        polygon: { lat: 47.691189, lng: 35.34202 },
        zoom: 14,
        type: 'country',
        id: 'grigorievka',
    },
    {
        name: 'Ивано-Анновка',
        polygon: { lat: 47.850412, lng: 35.377008 },
        zoom: 14,
        type: 'country',
        id: 'ivanoanovka',
    },
    {
        name: 'Кушугум',
        polygon: { lat: 47.709863, lng: 35.215563 },
        zoom: 14,
        type: 'country',
        id: 'kushugum',
    },
    {
        name: 'Лежино',
        polygon: { lat: 47.806931, lng: 35.362503 },
        zoom: 14,
        type: 'country',
        id: 'lezhino',
    },
    {
        name: 'Наталовка',
        polygon: { lat: 47.842768, lng: 35.354165 },
        zoom: 14,
        type: 'country',
        id: 'natalovka',
    },
    {
        name: 'Новоеленовка',
        polygon: { lat: 47.77296, lng: 35.367004 },
        zoom: 14,
        type: 'country',
        id: 'novoolenivka',
    },
    {
        name: 'Новоалександровка',
        polygon: { lat: 47.751537, lng: 35.367273 },
        zoom: 14,
        type: 'country',
        id: 'novoaleksandrovka',
    },
]
export const cities: Array<City> = [
    {
        name: 'Zaporizhzhia',
        polygon: { lat: 47.8319077, lng: 35.1204433 },
        zoom: 12.7
    },  
    {
        name: 'Balabino',
        polygon: { lat: 47.741867, lng: 35.21679 },
        zoom: 14,
    },
    {
        name: 'Veselyanka',
        polygon: { lat: 47.684058, lng: 35.373993 },
        zoom: 14,
    },
    {
        name: 'Grigorievka',
        polygon: { lat: 47.691189, lng: 35.34202 },
        zoom: 14,
    },
    {
        name: 'IvanoAnovka',
        polygon: { lat: 47.850412, lng: 35.377008 },
        zoom: 14,
    },
    {
        name: 'Kushugum',
        polygon: { lat: 47.709863, lng: 35.215563 },
        zoom: 14,
    },
    {
        name: 'Lezhino',
        polygon: { lat: 47.806931, lng: 35.362503 },
        zoom: 14,
    },
    {
        name: 'Natalovka',
        polygon: { lat: 47.842768, lng: 35.354165 },
        zoom: 14,
    },
    {
        name: 'Novoyelenovka',
        polygon: { lat: 47.77296, lng: 35.367004 },
        zoom: 14,
    },
    {
        name: 'Novoaleksandrovka',
        polygon: { lat: 47.751537, lng: 35.367273 },
        zoom: 14,
    },
]

import axios from 'axios'
export const backURL = process.env.REACT_APP_API_LINK_MAP
export let baseURL = `${backURL}`

export const google_key = process.env.REACT_APP_API_GOOGLE_KEY

export const captchaKey = process.env.REACT_APP_CAPTCHA

export const api = axios.create({
    baseURL,
})

baseURL = 'http://192.168.106.244:9984'
export const apiTest = axios.create({
    baseURL,
})

export const setHeadersBearer = (bearer: string) => {
    if (bearer !== null) {
        api.defaults.headers.common = {
            Authorization: `Bearer ${bearer}`,
        }
    }
}

export const setHeadersToken = (token: string) => {
    if (token) {
        api.defaults.headers.common = {
            Authorization: `Token ${token}`,
        }
    }
}

export const removeHeaders = () => {
    api.defaults.headers.common = {
        Authorization: ``,
    }
}

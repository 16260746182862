import React from 'react'
import { GoogleReCaptchaProvider, GoogleReCaptcha } from 'react-google-recaptcha-v3'
import { captchaKey } from '../../API'

interface PropsCaptcha {
    sendData: (captcha: string | null) => void
}

function Recaptcha(props: PropsCaptcha) {
    // const recaptchaRef = React.createRef<ReCAPTCHA>()
    return (
        <div
            style={{
                width: 'fit-content',
                margin: '10px auto 0 auto',
                display: 'flex',
            }}>
            {captchaKey && (
                <GoogleReCaptchaProvider
                    reCaptchaKey={captchaKey}
                    language={localStorage.getItem('lang') === 'ua' ? 'uk' : 'ru'}
                >
                    <GoogleReCaptcha onVerify={props.sendData} />
                </GoogleReCaptchaProvider>

                // <ReCAPTCHA
                //     ref={recaptchaRef}
                //     size={window.innerWidth < 500 ? 'compact' : 'normal'}
                //     sitekey={captchaKey}
                //     onChange={props.sendData}
                //     hl={localStorage.getItem('lang') === 'ua' ? 'uk' : 'ru'}
                // />
            )}
        </div>
    )
}

export default Recaptcha

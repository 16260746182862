/* eslint-disable import/no-anonymous-default-export */
export default {
    Покрытие: 'Покриття',
    Александровский: 'Олександрівський',
    Вознесеновский: 'Вознесенівський',
    'Бульвар Шевченко': 'Бульвар Шевченка',
    'Южный рынок': 'Південний ринок',
    Чапаева: 'Чапаєва',
    Металлургов: 'Металургів',
    'Первомайский пос.': 'Первомайський сел.',
    Молочная: 'Молочна',
    'Мирный поселок': 'Мирний селище',
    Культурная: 'Культурна',
    Чкаловский: 'Чоколівський',
    Кичкас: 'Заводський',
    'Космос МЖД': 'Космос МЗ',
    'Второмайский пос.': 'Второмайскій сел.',
    Космос: 'Космічний',
    Днепровский: 'Дніпровський',
    Хортицкий: 'Хортицький',
    Южный: 'Південний',
    Шевченковский: 'Шевченківський',
    Балабино: 'Балабине',
    Кушугум: 'Кушугум',
    Наталовка: 'Наталівка',
    'Ивано-Анновка': 'Івано-Ганнівка',
    Лежино: 'Лежине',
    Новоеленовка: 'Новооленівка',
    Новоалександровка: 'Новоолександрівка',
    Григоровка: 'Григорівка',
    Веселянка: 'Веселянка',
    'Введите адрес': 'Введіть адресу',
    Контакты: 'Контакти',
    'Запорожье': 'м. Запоріжжя',
    'Область': 'Запорізька об-ть',

    'Заява на проверку возможности подключения:': 'Заява на перевірку можливості підключення:',
    'Введите ФИО': 'Введіть ПІБ',
    'Телефон:': 'Телефон',
    'Ваш адрес': 'Ваша адреса:',
    Отправить: 'Надіслати',
    'Ваше имя:': "Ваше ім'я",
    'Ведите адрес': 'Введіть адресу',

    'Введите Ваше имя!': "Введіть ваше ім'я!",
    'Введите Ваш номер телефона!': 'Введіть Ваш номер телефону!',
    'ЗАЯВКА НА ПОДКЛЮЧЕНИЕ:': 'Зворотній дзвінок:',
    Подключить: 'Підключити',
    Закрыть: 'Закрити',

    'интернет-провйдер в городе Запорожье': 'інтернет-провйдер в місті Запоріжжя',
    'График работы: ежедневно 8:00 - 20:00': 'Графік роботи: щодня 8:00 - 20:00',
    'Техническая поддержка работает круглосуточно': 'Технічна підтримка працює цілодобово',

    'Заява успешно отправлена': 'Заява успішно відправлено',
    'Заява не была отправлена, попробуйте еще раз': 'Заява не була відправлена, спробуйте ще раз',
}

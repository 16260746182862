import React, { Component } from 'react'

import './index.css'

interface IPropsNotification {
    data: IPropsNotificationItem
}

interface IPropsNotificationItem {
    content: string
    title?: string | null
    type?: string
}

export class NotificationBox extends Component<IPropsNotification> {
    icon = (type: string) => {
        switch (type) {
            case 'success':
                return ''
            case 'warning':
                return ''
            default:
                break
        }
    }

    render() {
        const { content, title, type } = this.props.data
        return (
            <div className={'notification ' + (type ? `notification_${type}` : '')}>
                <div className='position-relative' style={{zIndex: 999}}>
                    {title ? (
                        <div className='d-flex justify-content-center align-items-center'>
                            {type && this.icon(type) ? <img src={this.icon(type)} alt={type} /> : ''}
                            <h3 className={`statusNotification statusNotification_${type}`}>{title}</h3>
                        </div>
                    ) : (
                        ''
                    )}
                    <div className='textNotification'>{content}</div>
                </div>
            </div>
        )
    }
}
